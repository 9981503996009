import request from "@/utils/request";

// 检验报告
export function inspectionReportList(params) {
  return request({ url: `/inspection_reports/`, method: "get", params });
}

export function inspectionReportRetrieve(params) {
  return request({ url: `/inspection_reports/${params.id}/`, method: "get", params });
}

export function inspectionReportNumber(params) {
  return request({ url: `/inspection_reports/number/`, method: "get", params });
}

export function inspectionReportCreate(data) {
  return request({ url: `/inspection_reports/`, method: "post", data });
}

export function inspectionReportUpdate(data) {
  return request({ url: `/inspection_reports/${data.id}/`, method: "put", data });
}

export function inspectionReportDestroy(data) {
  return request({ url: `/inspection_reports/${data.id}/`, method: "delete", data });
}

export function inspectionReportCommit(data) {
  return request({ url: `/inspection_reports/${data.id}/commit/`, method: "post", data });
}

export function inspectionReportSubmit(data) {
  return request({ url: `/inspection_reports/${data.id}/submit/`, method: "post", data });
}

export function inspectionReportVoid(data) {
  return request({ url: `/inspection_reports/${data.id}/void/`, method: "post", data });
}

export function inspectionFileUpload(data) {
  return request({
    url: "/inspection_files/",
    method: "post",
    data,
  });
}
